import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/trbm"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Disclaimers from "@tightrope/disclaimers"
import ChunkyFooter from "src/components/chunkyfooter"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const footerData = import("./data/footerData.json");

const prdbestData = import("./data/data.json");

export default function prdbest() {

  let css;
       if(isChrome()){
         css = `.trbm-module--trbm button {
           cursor:auto;
         }
         .trbm-module--trbm button:hover {
         transform: none;
        }
         `;
       }

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
     .footerlinks-module--br {
      display:none !important;
     }
     .trbm-module--trbm {
      min-height:100vh;
     }
    body {
      background-image: url(/img/printing-dark.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      background-position-x: center;
    }

    .trbm_cta {
        padding: 15px 100px;
        border: none;
        font-size: 35px;
        color: #fff;
        font-weight: bold;
        background: #43ba19;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s ease;
    }
    .trbm-module--trbm button {
      background: #43ba19;
    }
    #disclaimers-module--disclaimers {
      margin:28px 0 0 0;
      padding:0 0 20px 0;
      width:100%;
      }

     #disclaimers-module--disclaimers p {
      font-weight: 300;
      color: #333;
      font-size: 13px;
      text-align: center;
      font-family: Roboto,sans-serif;
      max-width: 700px;
      margin: 0 auto;
      }

      #disclaimers-module--disclaimers p a {
      font-weight:700;
      }
     footer {
      background:#fff;
      font-family: 'Roboto', sans-serif !important;
      display: block;
      position: relative;
      color: #333;
      border-radius: 5px;
      width: 100%;
    }
    footer ul {
      position: relative;
      list-style: none;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: right;
      color: #333;
      font-size: 12px;
      padding: 12px 0;
    }
    footer ul li {
      padding: 0 4px 0 0;
    }
    footer ul li:last-child {
      padding: 0;
    }
    footer ul li:after {
      content: '|';
      padding: 0 0 0 4px;
    }
    footer ul li:nth-child(6):after, footer ul li:nth-child(7):after, footer ul li:last-child:after {
      content: none;
      padding: 0;
    }
    footer ul a {
      color: #333;
    }
    @media only screen and (max-width: 768px) {
  
      footer {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
        color: #333;
        width: 100%;
        right:auto;
        bottom:auto;
    }
  
    footer ul {
      position: relative;
      display: flex;
      justify-content:center;
      align-items:center;
  }
    `}
    </style>
    <style type="text/css">
     {css}
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Trbm data={prdbestData}>
      <Disclaimers language='en-firefox'></Disclaimers>
    </Trbm>
    <ChunkyFooter data={footerData}><Disclaimers language='en-firefox'></Disclaimers></ChunkyFooter>
      </section>
      <RawFooter></RawFooter>
    </HomepageLayout>
  )
}
